.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.08);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.08);
  }
  70% {
    -moz-box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.08);
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.08);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.08);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.08);
  }
}
