@keyframes iconpulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes iconbounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
